import AuthContext from 'context/AuthProvider';
import { useContext } from 'react';

const useSegment = () => {
  const auth = useContext(AuthContext);

  const identify = () => {
    if (!window.navigator.userAgent.includes('Googlebot')) {
      if (auth.state.customerId) {
        window.analytics?.identify(auth.state.customerId, {
          email: auth.state.email,
          name: auth.state.object
            ? auth.state.object?.first_name + ' ' + auth.state.object?.last_name
            : 'Guest'
        });
      } else {
        window.analytics?.identify({
          email: ''
        });
      }
    }
  };
  const identifyManual = props => {
    if (!window.navigator.userAgent.includes('Googlebot')) {
      const segmentData = {
        email: props.email || undefined,
        name: props.name || undefined
      };
      if (props.gclid && props.gacid) {
        segmentData.gclid = props.gclid;
        segmentData.gacid = props.gacid;
      }
      window.analytics?.identify(props.id || undefined, segmentData);
    }
  };

  const track = (event, properties) => {
    if (!window.navigator.userAgent.includes('Googlebot')) {
      if (properties) {
        window.analytics?.track(event, properties);
      }
    }
  };

  return { track, identify, identifyManual };
};

export default useSegment;
